import request from "@/utils/request";

export const getList = (page,size,params)=>{
    return request({
        url:'/api/hado/match-season/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const changeNew = ()=>{
    return request({
        url:'/api/hado/match-season/change-new',
        method:'get',
    })
}
